<template>
    <v-data-table
        :items="orders"
        :loading="isLoading"
        :headers="filteredHeaders"
        :search="search"
        :options.sync="tableOptions"
        sort-by="id"
        sort-desc
        no-data-text="No hay pedidos para mostrar"
        @click:row="onRowSelected"
        hide-default-header
        :hide-default-footer="hideFooter"
        :footer-props="footerProps"
    >
        <template v-slot:item.id="{item}">
            {{ item.id }}
        </template>

        <template v-slot:item.customer="{item}">
            {{ customer(item.customer).fullName | capitalize }}
        </template>

        <template v-slot:item.date="{item}">
            {{ item.date | moment('calendar', 'now', {
                lastDay : '[Ayer]',
                sameDay : '[Hoy]',
                nextDay : '[Mañana]',
                lastWeek : '[Último] dddd',
                nextWeek : '[Próximo] dddd',
                sameElse : 'DD/MM/YYYY'
            }) }}
        </template>

        <template v-slot:item.state="{item}">
            <tui-order-state-chip :state="item.state"></tui-order-state-chip>
        </template>

        <template v-slot:item.total="{item}">
            {{ item.total | toCurrency }}
        </template>

        <template v-slot:item.payment="{item}">
            <tui-order-payment-status-chip :order="item"></tui-order-payment-status-chip>
        </template>

        <template v-slot:item.icons="{item}">
            <v-icon class="mr-2" v-if="item.source === 'Web'">mdi-web</v-icon>
            <v-icon class="mr-2" v-else>mdi-face-agent</v-icon>

            <v-icon class="mr-2" v-if="item.orderType === 'support'">mdi-lifebuoy</v-icon>

            <template v-if="item.commentCount > 0">
                <v-icon>mdi-comment-text-outline</v-icon>
                <span class="mr-2">{{ item.commentCount }}</span>
            </template>

            <v-tooltip bottom v-if="item.customer && isCustomerNew(customer(item.customer))">
                <template v-slot:activator="{ on }">
                    <v-icon class="mr-2" v-on="on">mdi-account-plus-outline</v-icon>
                </template>
                <span>Registro: {{ customer(item.customer).createdAt | moment('dddd D MMMM [del] YYYY') }}</span>
            </v-tooltip>
            
        </template>

        <template v-slot:item.actions="{item}">
            <v-btn icon @click.stop="onViewSelected(item)">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import TuiOrderStateChip from '@/ui/TUIOrderStateChip';
    import TuiOrderPaymentStatusChip from '@/ui/TUIOrderPaymentStatusChip';

    export default {
        components: {
            TuiOrderPaymentStatusChip,
            TuiOrderStateChip
        },

        props: {
            isLoading: Boolean,
            orders: Array,
            totalOrders: Number,
            search: String,
            options: Object,
            serverItemsLength: Number,
            hideFooter: Boolean,
            headers: {
                type: Array,
                default: () => [
                    { text: 'Pedido #', value: 'id', width: '140px' },
                    { text: 'Cliente', value: 'customer' },
                    { text: 'Fecha', value: 'date' },
                    { text: 'Estado', value: 'state' },
                    { text: 'Importe', value: 'total' },
                    { text: 'Pago', value: 'payment', hide: 'smAndDown' },
                    { text: '', value: 'icons', hide: 'smAndDown' },
                    { text: '', value: 'actions' }
                ]
            }
        },

        data() {
            return {
                tableOptions: {}
            };
        },

        computed: {
            footerProps() {
                return {
                    showFirstLastPage: true,
                    disableItemsPerPage: true,
                    itemsPerPageText: '',
                    itemsPerPageOptions: [ this.options ? this.options.itemsPerPage : -1 ]
                };
            },

            filteredHeaders() {
                let bp = this.$vuetify.breakpoint;
                let vm = this;

                return this.headers.filter(h => {
                    if(!h.hide) {
                        return true;
                    }

                    let hide = h.hide;

                    if(!Array.isArray(hide)) {
                        hide = [ hide ];
                    }

                    return !hide.find(e => {
                        if(typeof e === 'string') {
                            return bp[e];
                        }

                        if(typeof e === 'function') {
                            return e(vm);
                        }
                    });
                });
            }
        },

        watch: {
            tableOptions: {
                handler(val) {
                    this.$emit('update:options', val);
                },
                deep: true
            }
        },

        mounted() {

        },

        methods: {
            customer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            getOrderCountForDate(date) {
                let total = this.orders.reduce((a, v) => {
                    return a + (v.deliveryDate === date ? 1 : 0);
                }, 0);
                return total;
            },

            onRowSelected(order) {
                this.$emit('input', order);
            },

            onViewSelected(order) {
                this.$router.push({ name: 'orderView', params: { id: order.id } });
            },

            isCustomerNew(customer) {
                let days = this.$moment().diff(this.$moment(customer.createdAt), 'days');
                return days <= 31;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-data-table {
        ::v-deep .v-row-group__header td {
            border: none !important;
        }
    }
</style>
