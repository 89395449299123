<template>
    <template-layout>
        <template slot="drawers">
            <order-overview
                v-model="showOverviewDrawer"
                :order="selectedOrder"
            />

            <order-list-filters-drawer
                v-model="showFiltersDrawer"
                :defaultFilters="defaultFilters"
                :filters.sync="filters"
                :orders="orders"
            >
            </order-list-filters-drawer>
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Pedidos</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-text-field
                            class="mr-4"
                            v-model="search"
                            label="Buscar..."
                            color="primary"
                            dense hide-details flat prepend-icon="mdi-magnify"
                            clearable
                            style="max-width: 180px;"
                        />
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-4" icon @click="showFiltersDrawer = !showFiltersDrawer" v-on="on"><v-icon>mdi-tune</v-icon></v-btn>
                            </template>
                            <span>Filtrar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn fab x-small color="primary" class="elevation-0" :to="{ name: 'orderCreate' }" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                            </template>
                            <span>Nuevo pedido</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12" sm="6">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Cantidad de pedidos</h5>
                                <h1 class="display-1 black--text">{{ totalOrders }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">Monto total</h5>
                                <h1 class="display-1 black--text">{{ ordersTotalAmount | toCurrency }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>Pedidos</v-card-title>

                            <v-card-text>
                                <order-list-table
                                    ref="orderTable"
                                    v-model="selectedOrder"
                                    :is-loading="isLoading"
                                    :orders="orders"
                                    :totalOrders="totalOrders"
                                    :options.sync="tableOptions"
                                ></order-list-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout';
    import OrderListTable from '@/components/Order/OrderListTable';
    import OrderOverview from '@/components/Order/OrderOverview';
    import OrderListFiltersDrawer from './OrderListFiltersDrawer';

    export default {
        components: {
            TemplateLayout,
            OrderListTable,
            OrderOverview,
            OrderListFiltersDrawer
        },

        data() {
            return {
                showCommentsDrawer: false,
                showOverviewDrawer: false,
                showFiltersDrawer: false,

                commentsSelectedOrder: null,
                selectedOrder: null,

                isLoading: false,

                orders: [],
                totalOrders: 0,

                defaultFilters: {
                    state: 'state.open'
                },
                filters: {},

                search: '',

                tableOptions: {
                    page: 1,
                    itemsPerPage: 100
                }
            };
        },

        timers: {
            searchTimer: {
                time: 700,
                autostart: false
            }
        },

        computed: {
            ordersTotalAmount() {
                return this.orders.reduce((a, o) => a + o.total, 0);
            }
        },

        watch: {
            showOverviewDrawer(val) {
                if(!val) {
                    this.selectedOrder = null;
                }
            },

            selectedOrder(val) {
                this.showOverviewDrawer = (val != null);
            },

            search() {
                this.$timer.stop('searchTimer');
                this.$timer.start('searchTimer');

                this.updateRouteQuery();
            },

            tableOptions: {
                handler(val) {
                    this.updateRouteQuery();
                },
                deep: true
            },

            filters: {
                handler(val) {
                    this.updateRouteQuery();

                    this.fetchOrders();
                },

                deep: true
            }
        },

        mounted() {
            this.filters = JSON.parse(JSON.stringify(this.defaultFilters));

            this.updateFiltersFromQuery();
            // this.updateRouteQuery();
        },

        methods: {
            onViewCommentsSelected(order) {
                this.commentsSelectedOrder = order;
                this.showCommentsDrawer = true;
            },

            updateFiltersFromQuery() {
                let q = { ...this.$route.query };

                for(let key of Object.keys(this.filters)) {
                    if(q[key]) {
                        this.filters[key] = q[key];
                    }
                }

                if(this.$route.query.search) {
                    this.search = this.$route.query.search;
                }
            },

            updateRouteQuery() {
                let q = {};

                q = { ...this.filters };

                if(this.search) {
                    q.search = this.search;
                }

                this.$router.replace({ name: 'orderList', query: q }).catch(() => {});
            },

            searchTimer() {
                this.$timer.stop('searchTimer');

                this.fetchOrders();
            },

            fetchOrders() {
                this.isLoading = true;
                this.tableOptions.page = 1;
                this.totalOrders = 0;
                this.orders = [];

                let filters = {};

                if(this.search) {
                    filters.search = this.search;
                }
                else {
                    filters = { ...this.filters };
                }

                // let's avoid extra round trips, and just paginate client side, it's useful to see the total of orders per day and amount
                filters.offset = 0;
                filters.limit = 1000;

                filters.sortBy = 'id';
                filters.sortDirection = 'desc';

                this.$store.dispatch('order/fetchAll', filters).then((result) => {
                    this.orders = result.ids.map(id => this.$store.getters['order/getById'](id));
                    this.totalOrders = result.total;
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
