var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.orders,"loading":_vm.isLoading,"headers":_vm.filteredHeaders,"search":_vm.search,"options":_vm.tableOptions,"sort-by":"id","sort-desc":"","no-data-text":"No hay pedidos para mostrar","hide-default-header":"","hide-default-footer":_vm.hideFooter,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.customer(item.customer).fullName))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,'calendar', 'now', { lastDay : '[Ayer]', sameDay : '[Hoy]', nextDay : '[Mañana]', lastWeek : '[Último] dddd', nextWeek : '[Próximo] dddd', sameElse : 'DD/MM/YYYY' }))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('tui-order-state-chip',{attrs:{"state":item.state}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.total))+" ")]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_c('tui-order-payment-status-chip',{attrs:{"order":item}})]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [(item.source === 'Web')?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-web")]):_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-face-agent")]),(item.orderType === 'support')?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-lifebuoy")]):_vm._e(),(item.commentCount > 0)?[_c('v-icon',[_vm._v("mdi-comment-text-outline")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.commentCount))])]:_vm._e(),(item.customer && _vm.isCustomerNew(_vm.customer(item.customer)))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-account-plus-outline")])]}}],null,true)},[_c('span',[_vm._v("Registro: "+_vm._s(_vm._f("moment")(_vm.customer(item.customer).createdAt,'dddd D MMMM [del] YYYY')))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onViewSelected(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }